@img-path: '../images';
@font-path: '../fonts';

@font-face {
  font-family: 'Papyrus';
  src: url('@{font-path}/Papyrus.TTF') format('truetype');
}

body, html {
  font-family: Arial, Verdana, sans-serif;
}

body {
  background: url('@{img-path}/background_02.avif');
  background-size: cover;
}

h2, h3 {
  color: #a91f05;
  font-family: Papyrus, sans-serif;
}

h2:first-of-type {
  margin-top: 0;
}


a {
  font-family: Papyrus, sans-serif;
  text-decoration: none;
  color: #a91f05;
  &:active, &.active {
    font-weight: bold;
  }
}

p {
  text-align: justify;
}

/* ***********************
***** Event Formular *****
*********************** */
form {
  &#loginForm {
    width: 300px;
    margin: auto;
    h2 {
      width: 75px;
      margin: auto;
    }
  }
  .formButtonBox {
    margin-top: 10px;
    text-align: center;
  }
  #cke_1_contents {
    height: 500px !important;
  }
  .errorText {
    color: #FF0000;
  }
  p {
    text-align: left !important;
  }
}

/* ***************
***** Events *****
*************** */
#events {
  ul {
    margin-top: 0;
  }
  p {
    margin: 0;
    &:last-of-type {
      color: #ff0000;
      font-weight: bold;
    }
  }
}

#wrapper {
  width: 950px;
  height: 870px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  background-color: #c6c7c9;
  opacity: 0.95;
  border: 10px solid;
  -moz-border-image: url('@{img-path}/border_03.png') 40 44 44 40 repeat;
  -webkit-border-image: url('@{img-path}/border_03.png') 40 44 44 40 repeat;
  -o-border-image: url('@{img-path}/border_03.png') 40 44 44 40 repeat;
  border-image: url('@{img-path}/border_03.png') 40 44 44 40 fill repeat;
  border-radius: 15px;
}

/* Kopf */
#header {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 5px solid #E3CC58;
  border-radius: 2px;
  .header--logo {
    height: 140px;
  }

  .header--jap_text {
    height: 140px;
    margin-left: 20px;
  }

  .header--raum_01 {
    height: 140px;
    margin-left: 20px;
  }

  .header--raum_02 {
    height: 140px;
    margin-left: 20px;
  }
}

#claims,
#footer,
#menu {
  padding: 5px;
  opacity: 0.95;
  float: left;
  width: 190px;
}

#claims {
  margin-top: 50px;
}

#menu {
  ul.menu--ul {
    padding-left: 15px;
  }

  li.menu--button {
    margin-bottom: 10px;
  }

  li.menu--button:hover {
    background-color: darkgrey;
  }
}

#footer {
  clear: both;
  width: 920px;
}

/* Content */
#content {
  opacity: 0.95;
  float: right;
  width: 700px;
  height: 650px;
  margin-top: 10px;
  padding: 5px;
  overflow: auto;
}

.content--soon {
  font-weight: bold;
  font-size: large;
  margin-top: 200px;
  text-align: center;
  width: 450px;
  margin-left: auto;
  margin-right: auto;
  border: 5px solid red;
  border-radius: 5px;
  background-color: darkgrey;
  padding: 5px;
  h1 {
    margin: 0;
  }

}

#soonBox {
  font-weight: bold;
  font-size: large;
  margin-top: 150px;
  text-align: center;
  width: 450px;
  margin-left: auto;
  margin-right: auto;
}

img {
  &.logo {
    width: 250px;
  }

  &.klangmassage {
    width: 450px;
    border: 1px solid black;
    border-radius: 5px;
  }
}

span.price-green {
  color: green;
  float: right;
  margin-right: 150px;
}

span.price-red {
  color: red;
  float: right;
  margin-right: 150px;
}

/* ******************
***** Bootstrap *****
****************** */
.panel {
  .panel-heading {
    color: #a91f05;
    font-family: Papyrus, sans-serif;
    font-weight: bold;
    font-size: 1.5em;
  }
}